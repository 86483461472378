.content-box{
  width: 1160px;
  margin: 0 auto;
  .breadcrumb-box{
    height: 45px;
    padding: 10px 0;
    span{
      cursor: pointer;
    }
  }
  .contents{
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .content-left{
      width: 240px;
      min-height: 500px;
      border: 1px solid #ddd;
      border-radius: 20px;
      overflow: hidden;
      .content-left-title{
        height: 45px;
        font-size: 16px;
        color: #30790e;
        line-height: 45px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2px;
        background: #e7f3ee;
      }
      .tag-list{
        width: 100%;
        
        .tag-item{
          height: 45px;
          font-size: 16px;
          line-height: 45px;
          text-align: center;
          // text-indent: 60px;
          border-bottom: 1px dashed #ddd;
          cursor: pointer;
        }
        .tag-item:hover{
          color: #fff;
          background-color: #30790e;
        }
        .action{
          color: #fff;
          background-color: #30790e;
        }
      }
    }
    .content-right{
      width: 900px;
      min-height: 550px;
      background: #fff;
      padding: 0 30px 0px 20px;
      .content-right-list-item{
        padding-right: 0;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
      }
      .content-right-list-item:hover{
        background-color: #e0e8cd;
        .Img{
          background-size: 120%;
        }
        .emptyImg{
          background-size: 120%;
        }
      }
      .list-item-img-box{
        min-width: 275px;
        height: 165px;
        border: 1px solid #ddd;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: #fff no-repeat center ;
        transition: 0.5s;
      }
      .Img{
        background-size: 100%;
      }
      .emptyImg{
        background-size: 100%;
      }

      .detail-box{
        
	      .contentadddate{text-align:right;font-size:16px;color:#000000;line-height:30px;border-bottom: 2px solid #30790e;margin-bottom: 10px;}
	      .content{font-size:16px;line-height:40px;padding:20px 0px 30px 0px;}
        .nextpage{padding-bottom:30px;}
        .nextpage a{color:#000000;}

        
        ul{
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;

          li{
            font-family: auto;
            white-space: pre-wrap;
            text-align: left;
            list-style-type: disc;
          }
        }
        ol{
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;

          li{
            font-family: auto;
            white-space: pre-wrap;
            text-align: left;
            list-style-type: decimal;
          }
        }
      }
    }
  }
}

p{
  font-family: auto;
  white-space: pre-wrap;
  text-align: left;
  a{
    word-break: break-all;
  }
}
.ql-align-center{
  text-align: center;
}
.ql-align-right{
  text-align: right;
}
.ql-align-justify{
  font-family: auto;
  white-space: pre-wrap;
  text-align: justify;
}


.ql-size-small{
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}

.ql-video{
  width: 800px;
  height: 600px;
  text-align: center;
}