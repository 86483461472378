
.Carousel-box{
  width: 100%;
  height: 100%;
  position: relative;

  .Carousel-button{
    width: 15%;
    height: 100%;
    background-size: 15%;
    position: absolute;
    top: 0;
    opacity: 0.5;
  }
  .Carousel-left{
    background: url('../../images/bannerImg/left.png') no-repeat center;
    left: 0;
  }
  .Carousel-right{
    background: url('../../images/bannerImg/right.png') no-repeat center;
    right: 0;
  }
  .Carousel-button:hover{
    opacity: 1;
  }
}
.home-content-box{
  width: 1160px;
  margin: 0 auto;
  padding-top: 20px;
  .notice{
    width: 70px;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background: #666;
    margin-bottom: 10px;
  }
  .home-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .home-content-left{
      width: 630px;
      line-height: 40px;
      .left-item{
        width: 100%;
        .item-top{
          width: 100%;
          height: 42px;
          // margin-bottom: 10px;
          // border-width: 2px 2px 2px 0;
          // border-style: solid;
          // border-color: #30790e;
          // border-radius: 25px 0px 0px 25px;
          display: flex;
          justify-content: space-between;
          .item-title{  
            font-size: 20px;
            line-height: 38px;
            font-weight: 700;
            // background: #30790e;
            border-radius: 25px;
            color: #30790e;
            text-align: center;
          }
          .item-link{
            width: 80px;
            font-size: 18px;
            line-height: 38px;
            color: #30790e;
            cursor: pointer;
          }
        }
        .item-list{
          min-height: 210px;
          padding: 15px 15px 15px 0;
          .item-li{
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
            color: #000;
            cursor: pointer;
            .li-name{
              width: 450px;
              text-align: left;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
    .home-content-right{
      width: 500px;
      .right-item{
        width: 100%;
        text-align: left;
        .item-top{
          width: 100%;
          height: 42px;
          // margin-bottom: 10px;
          // border-width: 2px 2px 2px 0;
          // border-style: solid;
          // border-color: #30790e;
          // border-radius: 25px 0px 0px 25px;
          display: flex;
          justify-content: space-between;
          .item-title{  
            font-size: 20px;
            line-height: 38px;
            font-weight: 700;
            // background: #30790e;
            border-radius: 25px;
            color: #30790e;
            text-align: center;
          }
          .item-link{
            width: 80px;
            font-size: 18px;
            line-height: 38px;
            color: #30790e;
            cursor: pointer;
          }
        }
        .item-list{
          height: 422px;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          
          .item-li{
            width: 230px;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
            color: #000;
            .li-img{
              width: 230px;
              height: 160px;
              // background:url('../../images/videoImg/videoImg1.png') no-repeat 0 0;
              // background-size: 100% 100%;
              // text-align: center;
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;
              img{
                height: 100%;
              }
            }
            .li-name{
              width: 100%;
              font-size: 14px;
              text-align: center;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
              display: inline-block;
              margin-top: 10px;
            }
          }
        }
        .institutionImg{
          height: 368px;
          padding: 0;
          box-sizing: border-box;
          overflow: auto;
          .item-li{
            width: 150px;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
            color: #000;
            .li-img{
              width: 150px;
              height: 60px;
              background:url('../../images/videoImg/videoImg1.png') no-repeat 0 0;
              background-size: 100% 100%;
            }
            .li-name{
              font-size: 14px;
              text-align: center;
            }
          }
        }

        /* 隐藏标准的滚动条 */
        .institutionImg::-webkit-scrollbar {
          width: 0;
        }
        .institutionImg::-webkit-scrollbar {
          width: 0;
        }
        /* 隐藏 IE 和 Edge 浏览器的滚动条 */
        .institutionImg::-ms-scrollbar {
          width: 0;
        }
      }
    }
  }

  .link-list-title{
    width: 100%;
    font-size: 20px;
    line-height: 38px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 5px;
    color: #30790f;
    // background: #f6f6f6;
  }
  .link-list{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    a{
      display: flex;
      align-items: center;
    }
    .link-list-img{
      // width: 185px;
      height: 56px;
      margin-top: 5px;
      box-sizing: border-box;
    }
  }
}

.Affix-box{
  width: 82px;
  height: 500px;
  display: flex;
  flex-direction: column;
  position:fixed;
  top: calc(50% - 250px);
  right: calc(50% - 680px);
  .QRCode-item{
    width: 100%;
    height: 25%;
    font-size: 12px;
    text-align: center;
    img{
      width: 100%;
    }
    .QRCode-name{
      font-size: 12px;
      text-align: center;
      padding: 0 10px;
    }
  }
}