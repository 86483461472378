.nav {
  width: 1160px;
  height: 70px;
  // background: #30790f;
  display: flex;
  justify-content: space-between;
  align-items: end;
  .name{
    height: 70px;
    padding: 5px 0;
    font-size: 14px;
    // font-weight: 700;
    // line-height: 70px;
    text-align: left;
    color: #30790f;
    display: flex;
    align-items: center;

    .logo{
      width: 60px;
      height: 60px;
      background: url("../../images/logo.png") no-repeat 0px 0px;
      background-size: cover;
    }
    div{
      margin-left: 10px;
      font-size: 12.5px;
      line-height: 1;
      font-weight: 700;
      color: #000;
    }
    .name-text{
      font-size: 28px;
      margin-bottom: 10px;
      letter-spacing: 2.3px;
    }
  }
  ul{
    width: 50%;
    height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    li, a li{
      width: 100px;
      font-size: 16px;
      font-weight: 900;
      display: inline-block;
      list-style: none;
      color: #30790f;
      // background-color: #30790f;
      cursor: pointer;
    }
    li:hover{
      color: #fff;
      background-color: #30790f;
    }
    .action{
      color: #fff;
      background-color: #30790f;
    }
  }
}
